.App {
  /* display: flex;
  flex-direction: column; */
  text-align:left;
  /* justify-content: left; */
}



.app {
  align-content: left;
}

/* body {
  background-color: antiquewhite;
} */





#order-header {
  text-align: center;
  padding-top: 20px;
}

h1 {
  text-align: center;
  padding-top: 20px;
}





/* CSS */
.button {
  background-color: rgba(51, 51, 51, 0.05);
  border-radius: 8px;
  border-width: 0;
  color: #333333;
  cursor: pointer;
  display: inline-block;
  font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  list-style: none;
  margin: 0;
  padding: 10px 12px;
  text-align: center;
  transition: all 200ms;
  vertical-align: baseline;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}