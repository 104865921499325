/* body{
    background-color:white;
} */

/* .div-container{
    display:flex;
    justify-content:center;
    flex-direction:column;
} */

.pTag{
    text-align: center;
    padding-top:2%;
    font-weight:200;
    font-size:larger;
}


 @media screen and (max-width:800px){
    .pTag {
            text-align: center;
            padding-top: 4%;
            font-weight: 200;
            font-size: large;
        }
 }