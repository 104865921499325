@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;800&display=swap');

*,
*::before,
*::after {
    margin: 0;
    padding: 0;
}

html {
    scroll-snap-type: y mandatory;
}

body {
    scroll-behavior: smooth;

    background-color: #90ccf4;
    position: relative;
    font-family: 'Poppins', sans-serif;
    overflow: auto;
    height: 100%;
    margin: 0;
    padding: 0;
}



.webgl {
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}

section {
    height: 100vh;
    width: 100vw;
    scroll-snap-align: start;
    position: relative;
}

section .container {
    max-width: 1360px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 0 15px;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    justify-content: flex-start;
}

section.one .container {
    grid-template-areas: 'content ..'
}

section.two .container {
    grid-template-areas: '.. content'
}

secttion.three {
    width: 40%;
}

.hero {
    width: 100%;
    height: 100%;
    margin-bottom: 50px;

    grid-area: content;
    display: flex;
    align-items: left;
    justify-content: center;
    flex-direction: column;
}

h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 90px;
    line-height: 100px;
    font-weight: 700;
    height: 100px;
    width: 40%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

h2 {
    font-size: 60px;
    font-weight: 600;
    line-height: 70px;
    font-family: inherit;
    display: block;
    width: 100%;
}

h3 {
    font-size: 50px;
    font-weight: 400;
    line-height: 60px;
    margin-bottom: 30px;
    display: block;
    width: 100%;
}

p {
    font-size: 16px;
    line-height: 26px;
    font-weight: 300;
    display: block;
    width: 100%;
}


.loading-bar {
    position: fixed;
    top: 50%;
    width: 100%;
    height: 2px;
    z-index: 99;
    background: #ffffff;
    transform: scaleX(0);
    transition: transform 0.5s;
    transform-origin: top left;
}



.order-btn {
    text-decoration: none;
    color: black;
}

.order-btn:hover {
    text-decoration: none;
    color: white;
}

/* Add the following media query at the end of the CSS file */
@media (max-width: 768px) {

    #homePageh2 {
        display: flex;
        justify-content: center;
    }

    .homePage {
        display: flex;
        justify-content: center;
        padding-left: 5%;
    }

    h1 {
        font-size: 50px;
        line-height: 60px;
    }

    h2 {
        font-size: 40px;
        line-height: 50px;
    }

    h3 {
        font-size: 27px;
        line-height: 40px;
    }

    p {
        font-size: 14px;
        line-height: 24px;
    }

    section.one .container {
        grid-template-areas: 'content';
        grid-template-columns: 1fr;
    }

    section.two .container {
        grid-template-areas: 'content';
        grid-template-columns: 1fr;
    }

    .hero {
        display: flex;
        justify-content: center;
        /* padding-left:1%; */
        padding-right: 1%
    }

    .container {
        padding: 0 10px;
    }

    body {
        scroll-behavior: smooth;

        background-color: #90ccf4;
        position: relative;
        font-family: 'Poppins', sans-serif;
        overflow: auto;
        height: 100vh;
        margin: 0;
        padding: 0;
    }
}