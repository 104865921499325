
/* @import "https://cdn.jsdelivr.net/npm/bulma@0.9.4/css/bulma.min.css"; */

.orderPage{
    display: flex;
    justify-content: center;
    flex-direction:column;
    align-items: center;
    /* padding-bottom:5%;
    padding-top: 5%; */
    /* margin-bottom: 2%; */
    margin: 3%;
}
.formcontainer {
    display: flex;
    justify-content: center;

    background-color:whitesmoke;
    width: auto;
    padding: 1% 4% 1%;    
    font-style:bold;
    
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

    border-radius: 10px; /* Add this line to round the corners */
    font-size:larger;
    font-style:bold;
}

.buttonContainer{
    display: flex;
    justify-content: center;
    padding-top:2%;
    padding-bottom:2%;
}

.bagelList{

    list-style-type: none; /* Remove the bullet point */
    padding-top: 0.5em;/* Adjust the left indentation */
    text-align:left;
    white-space:normal;
}
li{
    margin-bottom: 10px;
}

.inputBox{
    width: 300px;
    height: 40px;
    border-radius: 4px;
    border: whitesmoke;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    /* margin-left:px; */
}
/* .inputNumberBox{
    margin-left:10px;
} */

.divItems{
    display: flex;
    flex-direction:row;
    justify-content: space-between;
}

input[type="number"]{
    width: 30px;
    height: 27px;
    border: whitesmoke;
    border-radius: 4px;
    background-color:white;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.15); */
    margin-left: 1%;
    font-size:14px;
    font-family: "Haas Grot Text R Web","Helvetica Neue",Helvetica,Arial,sans-serif;
    

}
.bagelList{
    padding:100;
}

input[type="text"]{
    font-size:16px;
    font-family:"Haas Grot Text R Web",
        "Helvetica Neue",
        Helvetica,
        Arial,
        sans-serif;

}



button {
    /* background-color: rgba(51, 51, 51, 0.05); */
    background-color:white;
    border-radius: 8px;
    border-width: 0;
    color: #333333;
    cursor: pointer;
    display: inline-block;
    font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    list-style: none;
    margin: 0;
    padding: 10px 12px;
    text-align: center;
    transition: all 200ms;
    vertical-align: baseline;
    white-space: nowrap;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.disclaimer{
    text-align: center;
    padding-top:1%;
    font-weight:300;
}

@media (max-width: 800px) {

    .actualForm{
        margin:5%;
    }

    .disclaimer {
        text-align: center;
        margin-top: -5%;
        font-weight: 400;
    }
    .formcontainer {
        width: 80%;
        margin: 40px auto;
        /* padding: 10px; */
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    }


input[type="number"] {
    -webkit-appearance: none;
    flex: 1;
    width: 30px;
    height: 27px;
    border:none;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-left: 10px;
    margin-right: 10px;
}

  .inputBox {
        -webkit-appearance: none;
      width: 100%;
      border-radius: 4px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      /* -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
      -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); */
  }

}